<template>
  <Disclosure
    as="nav"
    class="border-b border-gray-200 bg-white"
    v-slot="{ open }"
  >
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="flex h-16 justify-between">
        <div class="flex">
          <div class="flex flex-shrink-0 items-center">
            <nuxt-link class="min-w-80" to="/">
              <img
                v-if="logo === 'berenberg.png'"
                :src="`/${logo}`"
                class="block h-12"
              />
              <img v-else :src="`/${logo}`" class="block h-8" />
            </nuxt-link>
          </div>
          <div class="hidden sm:-my-px sm:flex sm:space-x-8">
            <nuxt-link
              to="/"
              :class="[
                !router.currentRoute.value.path.startsWith('/eventkalender')
                  ? 'border-[--primary] text-gray-500 text-[--primary]'
                  : 'text-gray-900  hover:border-gray-300 hover:text-gray-700 ',
                'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium',
              ]"
              >Dashboard
            </nuxt-link>

            <nuxt-link
              to="/eventkalender"
              :class="[
                router.currentRoute.value.path.startsWith('/eventkalender')
                  ? 'border-[--primary] text-gray-500 text-[--primary]'
                  : 'text-gray-900  hover:border-gray-300 hover:text-gray-700 ',
                'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium',
              ]"
              >Eventkalender
            </nuxt-link>
          </div>
        </div>
        <div class="hidden sm:ml-6 lg:flex sm:items-center">
          <button
            @click="openSearch()"
            type="button"
            class="rounded-[--full-round] bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-[--primary] focus:ring-offset-2"
          >
            <span class="sr-only">Toggle Search</span>
            <MagnifyingGlassIcon class="h-6 w-6" aria-hidden="true" />
          </button>

          <!-- Profile dropdown -->
          <Menu as="div" class="relative ml-3">
            <div>
              <MenuButton
                class="flex max-w-xs items-center rounded-[--full-round] bg-white text-sm focus:outline-none focus:ring-2 focus:ring-[--primary] focus:ring-offset-2"
              >
                <span class="sr-only">Open user menu</span>
                <div
                  class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-[--full-round] dark:bg-gray-600"
                >
                  <span class="font-medium text-gray-600 dark:text-gray-300">{{
                    userInitials(currentUser)
                  }}</span>
                </div>
              </MenuButton>
            </div>
            <transition
              enter-active-class="transition ease-out duration-200"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-[--md-round] bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <MenuItem v-for="item in userNavigation" :key="item.name">
                  <nuxt-link
                    :to="item.href"
                    @click.native="userMenu(item.name)"
                    :class="[
                      'block px-4 py-2 text-sm text-gray-700  hover:bg-gray-100',
                    ]"
                    >{{ item.name }}</nuxt-link
                  >
                </MenuItem>

                <MenuItem>
                  <button
                    :class="[
                      'block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-100 w-full text-left',
                    ]"
                    @click="handleLogout()"
                  >
                    Abmelden
                  </button>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
        <div class="-mr-2 flex items-center lg:hidden">
          <!-- Mobile menu button -->
          <DisclosureButton
            class="inline-flex items-center justify-center rounded-[--md-round] bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring--[--primary] focus:ring-offset-2"
          >
            <span class="sr-only">Open main menu</span>
            <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
      </div>
    </div>

    <DisclosurePanel class="lg:hidden">
      <div class="space-y-1 pt-2 pb-3">
        <DisclosureButton
          v-for="item in navigation"
          :key="item.name"
          @click="navTo(item.href)"
          :class="[
            item.current
              ? 'bg-indigo-50 border-[--primary] text-indigo-700'
              : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800',
            'block pl-3 pr-4 py-2 border-l-4 text-base font-medium w-full text-left',
          ]"
          :aria-current="item.current ? 'page' : undefined"
          >{{ item.name }}</DisclosureButton
        >

        <div v-if="currentUser.currentUser?.role.title === 'USER'">
          <DisclosureButton
            @click="navTo('/anfragen')"
            class="w-full text-left border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
            >Anfragen</DisclosureButton
          >

          <DisclosureButton
            @click="navTo('/angebote')"
            class="w-full text-left border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
            >Angebote</DisclosureButton
          >

          <DisclosureButton
            @click="navTo('/bestellungen')"
            class="w-full text-left border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
            >Bestellungen</DisclosureButton
          >
        </div>

        <div v-if="currentUser.currentUser?.role.title === 'ADMIN'">
          <DisclosureButton
            @click="navTo('/booker/anfragen')"
            class="w-full text-left border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
            >Anfragen</DisclosureButton
          >

          <DisclosureButton
            @click="navTo('/booker/bestellungen')"
            class="w-full text-left border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
            >Bestellungen</DisclosureButton
          >

          <DisclosureButton
            @click="navTo('/booker/kunden')"
            class="w-full text-left border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
            >Auftraggeber</DisclosureButton
          >

          <DisclosureButton
            @click="navTo('/booker/settings')"
            class="w-full text-left border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
            >Einstellungen</DisclosureButton
          >
        </div>

        <div v-if="currentUser.currentUser?.role.title === 'CLIENT'">
          <DisclosureButton
            @click="navTo('token')"
            class="w-full text-left border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
            >Token</DisclosureButton
          >

          <DisclosureButton
            @click="navTo('/benutzer')"
            class="w-full text-left border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
            >Benutzer</DisclosureButton
          >
        </div>
      </div>
      <div class="border-t border-gray-200 pt-4 pb-3">
        <div class="flex items-center px-4">
          <div class="flex-shrink-0">
            <div
              class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-[--full-round] dark:bg-gray-600"
            >
              <span class="font-medium text-gray-600 dark:text-gray-300">{{
                userInitials(currentUser)
              }}</span>
            </div>
          </div>
          <div class="ml-3">
            <div class="text-base font-medium text-gray-800">
              {{ currentUser.currentUser?.name }}
            </div>
            <div class="text-sm font-medium text-gray-500">
              {{ currentUser.currentUser?.email }}
            </div>
          </div>
          <button
            type="button"
            class="ml-auto flex-shrink-0 rounded-[--full-round] bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
          >
            <span class="sr-only">View notifications</span>
            <BellIcon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div class="mt-3 space-y-1">
          <DisclosureButton
            v-for="item in userNavigation"
            :key="item.name"
            as="a"
            :href="item.href"
            class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
            >{{ item.name }}</DisclosureButton
          >

          <DisclosureButton
            @click="handleLogout()"
            as="a"
            href=""
            class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
            >Abmelden</DisclosureButton
          >
        </div>
      </div>
    </DisclosurePanel>
  </Disclosure>
  <Search :open="toggleSearch" @closeSearch="openSearch" />
</template>

<script setup>
import { ref } from "vue";

import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";

import {
  Bars3Icon,
  BellIcon,
  XMarkIcon,
  MagnifyingGlassIcon,
} from "@heroicons/vue/24/outline";
import { useAuthStore } from "~/stores/AuthStore";

const props = defineProps(["logo"]);
const router = useRouter();
const { onLogout } = useApollo();
const currentUser = useAuthStore();
const toggleSearch = ref(false);

const navTo = (route) => {
  navigateTo(route);
};

function handleLogout() {
  onLogout();
  window.location.reload();
}

function userInitials(user) {
  if (user.currentUser) {
    return (
      user.currentUser.firstname.charAt(0) + user.currentUser.name.charAt(0)
    );
  }
}

function openSearch() {
  toggleSearch.value = !toggleSearch.value;
}

const navigation = [
  {
    name: "Dashboard",
    href: "/",
    current: false,
  },
  {
    name: "Eventkalender",
    href: "/eventkalender",
    current: false,
  },
];

const userNavigation = [{ name: "Ihr Profil", href: "/profil" }];
</script>
