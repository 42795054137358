import { gql } from "@apollo/client/core";

export const CREATE_CLIENT = gql`
  mutation createClient($client: CreateClientInput!) {
    CreateClient(input: $client) {
      id
      name
      slug
    }
  }
`;

export const GET_CLIENT = gql`
  query ($id: ID!) {
    SingleClient(id: $id) {
      id
      name
      has_sepa
      Details {
        detailId: id
        ap_title
        ap_salutation
        ap_name
        ap_firstname
        ap_email
        ap_phone
        primary_color
        secondary_color
        primary_logo
        kickback
        cashback
        provision
        pdf_template
      }
    }
  }
`;

export const GET_CLIENT_BY_SLUG = gql`
  query ($slug: String!) {
    getClientBySlug(slug: $slug) {
      id
      name
      Details {
        primary_logo
        primary_color
        secondary_color
      }
    }
  }
`;

export const GET_CLIENT_ORDERS = gql`
  query MyQuery {
    ClientBookings {
      data {
        id
        custom_event
        user {
          firstname
          name
          email
        }
        client {
          name
        }
      }
    }
  }
`;

export const GET_UNEDITED_ORDERS = gql`
  query MyQuery {
    UneditedResolver {
      id
      custom_event
      user {
        firstname
        name
        email
      }
      client {
        name
      }
    }
  }
`;

export const GET_ADMIN_STATS = gql`
  query MyQuery {
    UneditedRequestCount
    CurrentOrderCount
  }
`;

export const GET_ALL_ORDERS = gql`
  query MyQuery($first: Int!, $page: Int!) {
    ClientBookings(
      stati: ["ORDERED", "BILL_SEND", "PAID"]
      first: $first
      page: $page
    ) {
      paginatorInfo {
        count
        currentPage
        total
        lastPage
      }
      data {
        id
        custom_event
        Bill {
          status
        }
        user {
          firstname
          name
          email
        }
        client {
          name
        }
      }
    }
  }
`;

export const UPDATE_CLIENT = gql`
  mutation MyMutation(
    $id: ID!
    $detailId: ID!
    $ap_title: String
    $ap_salutation: String
    $ap_firstname: String
    $ap_name: String
    $ap_email: String
    $ap_phone: String
    $name: String
    $primary_color: String
    $secondary_color: String
    $primary_logo: String
    $provision: Int
    $kickback: Int
    $cashback: Int
    $pdf_template: String
  ) {
    UpdateClient(
      input: {
        id: $id
        name: $name
        Details: {
          update: {
            id: $detailId
            ap_title: $ap_title
            ap_salutation: $ap_salutation
            ap_name: $ap_name
            ap_email: $ap_email
            ap_firstname: $ap_firstname
            ap_phone: $ap_phone
            primary_color: $primary_color
            secondary_color: $secondary_color
            primary_logo: $primary_logo
            provision: $provision
            kickback: $kickback
            cashback: $cashback
            pdf_template: $pdf_template
          }
        }
      }
    ) {
      name
    }
  }
`;

export const GET_CLIENT_USERS = gql`
  query MyQuery($client_id: ID!) {
    clientUsers(client_id: $client_id) {
      data {
        name
        firstname
        email
      }
    }
  }
`;

export const GET_CLIENT_MANAGERS = gql`
  query MyQuery($client_id: ID!) {
    clientManagers(client_id: $client_id) {
      data {
        id
        name
        firstname
        email
      }
    }
  }
`;

export const GET_OWN_CLIENT_USERS = gql`
  query MyQuery {
    clientOwnUsers {
      data {
        name
        firstname
        email
        created_at
      }
    }
  }
`;

export const GET_ORDERS = gql`
  query {
    me {
      name
      email
      orders: Orders {
        id
        ticket_count
        custom_event
        offers {
          status
        }
      }
      requests: Requests {
        id
        ticket_count
        custom_event
        offers {
          status
        }
      }
    }
  }
`;

export const GET_CLIENT_TOKEN_STATS = gql`
  query MyQuery {
    ClientTokenStats {
      user_id
      sent
    }
  }
`;

export const GET_TOKEN_INFO = gql`
  query {
    TokenCount
    ActivatedTokenCount
    SendTokenCount
  }
`;

export const GET_SALES = gql`
  query MyQuery($id: String!) {
    Sales: SalesResolver(client_id: $id) {
      sums
      months
      kickback
      cashback
      provision
    }
  }
`;

/*export const GET_CLIENT_MANAGERS = gql`
  query MyQuery($client_id: ID!) {
    clientManagers(client_id: $client_id) {
      data {
        id
        name
        firstname
        email
      }
    }
  }
`;*/

export const VALIDATE_TOKEN = gql`
  query ($token: String!) {
    getToken(code: $token, Available: true) {
      id
    }
  }
`;

export const CREATE_ORDER = gql`
  mutation (
    $id: ID!
    $ticket_count: Int!
    $ticket_type: String!
    $type: String!
    $custom_event: String!
    $event_title: String!
    $message: String
  ) {
    createOrder(
      event_date_id: $id
      ticket_count: $ticket_count
      ticket_type: $ticket_type
      type: $type
      custom_event: $custom_event
      event_title: $event_title
      message: $message
    ) {
      id
      ticket_count
      type
    }
  }
`;

export const SET_REFERENCE = gql`
  mutation ($id: ID!, $reference: String!) {
    updateReference(id: $id, reference: $reference) {
      id
      reference
    }
  }
`;

export const UPSERT_OFFER_POSITION = gql`
  mutation (
    $id: ID
    $offer_id: ID!
    $qty: Int!
    $title: String!
    $is_ticket: Boolean!
    $foreign_selling: Boolean!
    $optional: Boolean!
    $provision: Boolean!
    $price: Int!
    $ek_price: Int!
    $tax: Int!
    $description: String
  ) {
    upsertOfferPosition(
      id: $id
      offer_id: $offer_id
      qty: $qty
      title: $title
      is_ticket: $is_ticket
      foreign_selling: $foreign_selling
      optional: $optional
      provision: $provision
      price: $price
      ek_price: $ek_price
      tax: $tax
      description: $description
    ) {
      id
      title
      price
      description
    }
  }
`;

export const DELETE_OFFER_POSITION = gql`
  mutation ($id: ID!) {
    deletePosition(id: $id) {
      id
    }
  }
`;

export const DELETE_USER = gql`
  mutation ($id: ID!) {
    deleteUser(id: $id) {
      id
    }
  }
`;

export const DECLINE_OFFER = gql`
  mutation ($id: ID!, $status_date: String) {
    declineOffer(id: $id, status: "DECLINED", status_date: $status_date) {
      id
      status
    }
  }
`;

export const TOGGLE_OFFER_POSITION = gql`
  mutation ($id: ID!, $enabled: Boolean!) {
    toggleOptional(id: $id, enabled: $enabled) {
      enabled
    }
  }
`;

export const GET_SINGLE_OFFER = gql`
  query ($id: ID!) {
    offer(id: $id) {
      id
      status
      order_id
      Order {
        id
        status
        custom_event
        ticket_count
        ticket_type
        user_id
      }
      positions {
        id
        offer_id
        title
        qty
        price
        ek_price
        tax
        is_ticket
        foreign_selling
        optional
        provision
        description
        enabled
      }
    }
  }
`;

export const GET_FINAL_ORDER = gql`
  query ($id: ID!) {
    FinalOrder(id: $id) {
      id
      client_id
      ticket_count
      custom_event
      acceptedOffer {
        id
        status
        message
      }
      Bill {
        id
        easybill_id
        status
        total
      }
    }
  }
`;

export const GET_ORDER_OVERVIEW = gql`
  query ($id: ID!) {
    FinalOrder(id: $id) {
      id
      ticket_count
      ordered_at
      BillingAddress {
        id
        firstname
        name
        street
        house_nr
        zip
        city
      }
      DeliveryAddress {
        id
        firstname
        name
        street
        house_nr
        zip
        city
      }
      Bill {
        id
        status
        paylink
        easybill_id
      }
    }
  }
`;

export const DELETE_OFFER = gql`
  mutation ($id: ID!) {
    deleteOffer(id: $id) {
      id
    }
  }
`;

export const ACTIVATE_BILL = gql`
  mutation ($billing_id: String!) {
    ActivateBillResolver(billing_id: $billing_id) {
      id
    }
  }
`;

export const DOWNLOAD_BILL = gql`
  mutation MyMutation {
    downloadBill
  }
`;

export const GET_OFFERS = gql`
  query ($id: ID!) {
    GetOffers(order_id: $id) {
      id
      message
      deadline
      status
      positions {
        id
        offer_id
        title
        qty
        price
        ek_price
        tax
        is_ticket
        foreign_selling
        optional
        provision
        description
        enabled
      }
    }
    SingleOrder(id: $id) {
      id
      custom_event
      ticket_count
      ticket_type
      offer_id
      message
      reference
      Bill {
        id
        easybill_id
        paylink
        status
        total
      }
      BillingAddress {
        id
        firstname
        name
        street
        house_nr
        zip
        city
      }
      DeliveryAddress {
        id
        firstname
        name
        street
        house_nr
        zip
        city
      }
      user {
        firstname
        name
        salutation
        email
      }
      client {
        id
        name
        Details {
          provision
          kickback
          cashback
        }
      }
    }
  }
`;
export const GET_OPEN_OFFERS = gql`
  query ($id: ID!) {
    GetOpenOffers(order_id: $id) {
      id
      message
      deadline
      status
      positions {
        id
        offer_id
        title
        qty
        price
        ek_price
        tax
        is_ticket
        foreign_selling
        optional
        provision
        description
        enabled
      }
    }
    SingleOrder(id: $id) {
      id
      custom_event
    }
  }
`;

export const GET_OPEN_OFFERS_OVERVIEW = gql`
  query ($id: ID!) {
    GetOpenOffers(order_id: $id) {
      created_at
      id
      message
      deadline
      status
      status_date
    }
  }
`;

export const CREATE_OFFER = gql`
  mutation ($order_id: ID!, $deadline: String, $message: String) {
    createOffer(
      input: { order_id: $order_id, deadline: $deadline, message: $message }
    ) {
      id
      message
      deadline
      status
      positions {
        id
        title
        qty
        price
        tax
      }
    }
  }
`;

export const SET_OFFER_STATUS = gql`
  mutation ($id: ID!, $status: String!) {
    setOfferStatus(id: $id, status: $status) {
      id
      title
      status
    }
  }
`;

export const SEND_OFFER = gql`
  mutation ($id: ID!) {
    sendOffer(id: $id, status: "OFFERED") {
      id
      title
      status
    }
  }
`;

export const CHECKOUT_ORDER = gql`
  mutation (
    $id: ID!
    $offer_id: String!
    $order_date: DateTime!
    $voucher: String
    $delivery_email: String!
    $delivery_zip: String!
    $delivery_type: String!
    $delivery_street: String!
    $delivery_name: String!
    $delivery_house_nr: String!
    $delivery_firstname: String!
    $delivery_country: String!
    $delivery_city: String!
    $billing_email: String!
    $billing_zip: String!
    $billing_type: String!
    $billing_street: String!
    $billing_name: String!
    $billing_house_nr: String!
    $billing_firstname: String!
    $billing_country: String!
    $billing_city: String!
  ) {
    checkoutOrder(
      input: {
        id: $id
        offer_id: $offer_id
        ordered_at: $order_date
        status: "ORDERED"
        voucher: $voucher
        DeliveryAddress: {
          create: {
            street: $delivery_street
            type: $delivery_type
            house_nr: $delivery_house_nr
            zip: $delivery_zip
            city: $delivery_city
            country: $delivery_country
            firstname: $delivery_firstname
            name: $delivery_name
            email: $delivery_email
          }
        }
        BillingAddress: {
          create: {
            street: $billing_street
            type: $billing_type
            house_nr: $billing_house_nr
            zip: $billing_zip
            city: $billing_city
            country: $billing_country
            firstname: $billing_firstname
            name: $billing_name
            email: $billing_email
          }
        }
      }
    ) {
      message
      DeliveryAddress {
        street
        zip
        type
      }
      BillingAddress {
        id
      }
    }
  }
`;

export const DELETE_REQUEST = gql`
  mutation ($id: ID!) {
    deleteOrder(id: $id) {
      id
    }
  }
`;

export const ADMIN_REJECT_REQUEST = gql`
  mutation ($id: ID!, $status_date: String) {
    rejectOrder(id: $id, status: "ADMIN_CANCELED", status_date: $status_date) {
      id
    }
  }
`;

export const CREATE_TOKENS = gql`
  mutation ($client_id: ID!, $count: Int!) {
    CreateTokenResolver(client_id: $client_id, count: $count) {
      code
    }
  }
`;

export const CURRENT_USER = gql`
  query MyQuery {
    currentUser {
      id
      email
      phone
      firstname
      name
      client_id
      title
      salutation
    }
  }
`;
export const GET_USER_ADDRESSES = gql`
  query getUserAddresses {
    getUserAddresses {
      name
      firstname
      email
      country
      id
      street
      house_nr
      zip
      city
      type
    }
  }
`;

export const GET_USER_CHECKOUT_ADDRESSES = gql`
  query GetUserCheckoutAddresses {
    getUserBillingAddresses {
      name
      firstname
      email
      country
      id
      street
      house_nr
      zip
      city
      type
    }
    getUserDeliveryAddresses {
      name
      firstname
      email
      country
      id
      street
      house_nr
      zip
      city
      type
    }
  }
`;

export const CREATE_MANAGER = gql`
  mutation (
    $client: String!
    $email: String!
    $name: String!
    $firstname: String!
    $password: String!
  ) {
    CreateManager(
      client: $client
      email: $email
      name: $name
      firstname: $firstname
      password: $password
    ) {
      firstname
      name
    }
  }
`;

export const UPDATE_USER = gql`
  mutation (
    $name: String
    $firstname: String
    $email: String
    $title: String
    $salutation: String
    $phone: String
  ) {
    updateCurrentUser(
      name: $name
      firstname: $firstname
      email: $email
      title: $title
      salutation: $salutation
      phone: $phone
    ) {
      name
    }
  }
`;

export const SEND_TOKEN = gql`
  mutation (
    $email: String!
    $firstname: String
    $name: String
    $title: String
    $salutation: String
  ) {
    SendTokenResolver(
      email: $email
      firstname: $firstname
      name: $name
      title: $title
      salutation: $salutation
    ) {
      id
    }
  }
`;

export const SEND_BATCH_TOKEN = gql`
  mutation ($emails: [String]!) {
    SendBatchTokenResolver(emails: $emails) {
      id
    }
  }
`;

export const TOGGLE_ROLE = gql`
  mutation ($email: String!) {
    DevRoleToggleResolver(email: $email) {
      id
    }
  }
`;

export const REGISTER_USER = gql`
  mutation (
    $name: String!
    $firstname: String!
    $token: String!
    $email: String!
    $password: String!
    $client_id: String!
    $salutation: String!
    $password_confirmation: String!
  ) {
    register(
      input: {
        name: $name
        firstname: $firstname
        salutation: $salutation
        email: $email
        password: $password
        password_confirmation: $password_confirmation
        client_id: $client_id
        token: $token
      }
    ) {
      token
      status
    }
  }
`;
